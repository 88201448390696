import styled from "styled-components";
import { Color, Font, breakpoints } from "@/shared/Constants";
import { TwoxTwoWH , TwoxTwoTitle, TwoxTwoVolanta } from "../mixins-modules"; 
export const ContentTwoxTwoVisual = styled.div`
  ${TwoxTwoWH}
  .contentVideo{
      height: 650px;
      ${breakpoints.phone} {
        height: 300px;
      }
    }
  .mt {
    ${props => props.mamsEnabled && ""}
    h2.title{
      ${TwoxTwoTitle}
        margin-bottom:15px;
        ${breakpoints.phone} {
          font-size: 22px;
          line-height: 23px;
      }
    }
    .volanta{
      ${TwoxTwoVolanta}
      ${breakpoints.phone} {
          font-size: 22px;
          line-height: 23px;
      }
    }
  }
  picture{
    section{
      top: 0;
      .futbol-header{
        align-items: flex-start;
        .futbol-header-info {
          flex-direction:column-reverse;
        }
      }
    }
    .tenis-mam {
      top: 40px;
      right: 20px;
    }
  }
`;
