import { ContentTwoxTwoVisual } from "./styles";
import { VisualArticle } from "@/widgets/Nota/Templates/stylesModulesGenericVisual.js";
import ContentTitle from "@/shared/ContentTitle";
import ContentImage from "@/shared/ContentImage";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import VideoLoop from "@/shared/VideoLoop";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import MAM from "@/shared/MAM";
import useNewsDataModules from "@/hooks/useNewsDataModules";

const TwoxTwoVisual = (props) => {
  const {
    asPath,
    author = [],
    authorSignature,
    containerName,
    iconMultimedia,
    imagePreview,
    imageWeb,
    isPreview,
    mams,
    payWallExclusive,
    section = [],
    subtitle,
    title,
    titleLocation,
    url,
    urlTarget,
    videosOpenLoop,
    videoLoop
  } = useNewsDataModules(props, {
    desktop: ["square_lg"],
    mobile: ["square_md"],
  });
  return (
    <VisualArticle>
      <ContentTwoxTwoVisual mamsEnabled={mams?.enabled}>
        <ContentTitle
          author={author}
          authorSignature={authorSignature}
          title={title}
          subtitle={subtitle}
          section={section}
          payWallExclusive={payWallExclusive}
          titleLocation={titleLocation}
        />
        {videosOpenLoop?.enabled ? 
        <VideoLoop titleLocation={titleLocation} videoLoop={videoLoop}/>
        :<picture className={`${titleLocation}`}>
          {mams?.enabled && <MAM sport={mams.deporte} match={mams.idPartido} type="home" isPreview={isPreview} />}
          {isPreview ? (
            imagePreview && <ContentImage iconMultimedia={iconMultimedia} data={imagePreview} alt={title} />
          ) : (
            <ContentImage iconMultimedia={iconMultimedia} data={imageWeb} alt={title} />
          )}
        </picture>}
        <LinkAbsolute href={url} target={urlTarget} aria-label={title} onClick={() => clickContent({contentType: title, contentList: containerName},url,asPath)}/>
      </ContentTwoxTwoVisual>
    </VisualArticle>
  );
};

export default TwoxTwoVisual;
